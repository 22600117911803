// import { Cloudinary } from 'cloudinary-core';

require('../sass/bundle.scss');
// require('cloudinary-video-player/dist/cld-video-player.css');
// require('cloudinary-video-player/dist/cld-video-player.light.min.css');
//
//
// const playerContainer = document.getElementById('voeux-player-container');
// const videoName = playerContainer.getAttribute('data-name');
// const cld = Cloudinary.new({ cloud_name: 'qr3f3t3b' });
// // Initialize player
// const player = cld.videoPlayer('voeux-player');
//
// // Modify player source and play hls adaptive streaming
// player.source(videoName).play();
// const elements = document.getElementsByClassName('vjs-cloudinary-button');
// while (elements.length > 0) {
//   elements[0].parentNode.removeChild(elements[0]);
// }
